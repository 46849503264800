import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

class ProjectsPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <section>
          <Helmet title="Projects" />
          <h1>Projects</h1>
          <ul className="mt">
            <li>
              <h3>
                <Link to="/tv-shows/">TV Shows</Link>
              </h3>
            </li>
            <li>
              <h3>
                <Link to="/movies/">Movies</Link>
              </h3>
            </li>
            <li>
              <h3>
                <Link to="/a-tough-act-productions/">
                  A Tough Act Productions
                </Link>
              </h3>
            </li>
          </ul>
        </section>
      </Layout>
    )
  }
}

export default ProjectsPage
